import { useState } from "react";
import './index.scss';

const getXpNeeded = (level: number) => {
    const levels = [
        {
            level: 0,
            xp: 0
        }, {
            level: 10,
            xp: 115200
        }, {
            level: 20,
            xp: 447e3
        }, {
            level: 30,
            xp: 1336300
        }, {
            level: 40,
            xp: 3722400
        }, {
            level: 50,
            xp: 10133300
        }, {
            level: 60,
            xp: 27374200
        }, {
            level: 70,
            xp: 73762700
        }, {
            level: 80,
            xp: 198606800
        }, {
            level: 90,
            xp: 534633200
        }, {
            level: 100,
            xp: 1439116e3
        }, {
            level: 110,
            xp: 3873766100
        }
    ];
    return levels.find(l => l.level === level)?.xp || 0;
}

const getNeededWager = (targetXp: number, currentXp: number) => {
    return parseFloat(((targetXp - currentXp) / 400).toFixed(2));
}

const getDiceBurnAmount = (targetXp: number, currentXp: number) => {
    const toWager = parseFloat(((targetXp - currentXp) / 400).toFixed(2));
    return parseFloat((toWager/20).toFixed(2)).toLocaleString()
}

const setLocalStorageXp = (xp: number) => {
    localStorage['calculator_xp'] = xp;
}
const setLocalStorageLevel = (level: number) => {
    localStorage['calculator_level'] = level;
}
const getLocalStorageXp = () => {
    return parseFloat(localStorage['calculator_xp']) || 0;
}
const getLocalStorageLevel = () => {
    return parseInt(localStorage['calculator_level']) || 10;
}

export const Calculator = () => {
    const [level, setLevel] = useState(getLocalStorageLevel() || 10);
    const [xp, setXp] = useState(getLocalStorageXp() || 0);
    const setUsersXp = (xp: any) => {
        const amount = parseInt(xp);
        if(Number.isInteger(amount)) {
            setLocalStorageXp(amount);
            return setXp(amount);
        }
        setLocalStorageXp(amount);
        setXp(0);
    }
    const setUsersLevel = (level: number) => {
        setLocalStorageLevel(level);
        setLevel(level);
    }

    const xpNeeded = getXpNeeded(level);
    console.log(`Needed for lvl ${level} is ${xpNeeded}`)

    const wagerMore = <p>
        You need to wager <span className="highlight">{getNeededWager(xpNeeded, xp).toLocaleString()}</span> <img src="./assets/coin.png" alt="CSGORoll coin"/> to reach <span className="highlight">level {level}</span>
    </p>
    const alreadyReached = <p>
        You have already reached level {level}
    </p>
    return (
        <>
        <div className="calculator">
            <h3>Easily calculate how much you need to wager for a new level </h3>
            <div className="input-container">
                <div>
                    <p>Your current XP</p>
                    <input placeholder="Current XP" type="tel" onChange={(e) => {setUsersXp(e.currentTarget.value)}} defaultValue={xp}/>
                </div>
                <div>
                    <p>Level you want to reach</p>
                    <select onChange={(e) => {setUsersLevel(parseInt(e.currentTarget.value))}} defaultValue={level} >
                        <option value={10} className="grey">Level 10</option>
                        <option value={20} className="grey">Level 20</option>
                        <option value={30} className="grey">Level 30</option>
                        <option value={40} className="grey">Level 40</option>
                        <option value={50} className="grey">Level 50</option>
                        <option value={60} className="gold">Level 60</option>
                        <option value={70} className="gold">Level 70</option>
                        <option value={80} className="green">Level 80</option>
                        <option value={90} className="green">Level 90</option>
                        <option value={100} className="red">Level 100</option>
                        <option value={110} className="red">Level 110</option>
                    </select>
                </div>
            </div>
            <div className="details">
                <p>Currently, you have <span className="highlight">{xp} XP</span></p>
                <p>You need <span className="highlight">{xpNeeded} XP</span> to reach <span className="highlight">level {level}</span> </p>
                {xp < xpNeeded ? wagerMore : alreadyReached}
            </div>
        </div>
        <div className="dice-strategy">
            <h1>Dice strategy</h1>
           {xp < xpNeeded &&  <p>Using Dice strategy, you need to burn aprox. <span className="highlight">{getDiceBurnAmount(xpNeeded, xp)} <img src="./assets/coin.png" alt="CSGORoll coin"/></span> to reach <span className="highlight">level {level}</span></p>}
        
            <h3> How does dice strategy work? </h3>
            <img src="./assets/dice-strategy.png" alt="Dice strategy"/>
            <p> Dice strategy is a secure method of getting most out of your coins.</p>
            <p>Using this strategy is a guaranteed loss method but will allow you to wager around 20x of the amount of coins you are burning.</p>
            <h4>How to do it ?</h4>
            <ol>
                <li>Divide your current balance by 500 (So if you have 500 coins, you wager 1c per roll)</li>
                <li>Setup auto dice option as displayed on image</li>
                <li>Let it run and collect free XP and gems</li>
            </ol>

            <p>https://www.csgoroll.com/en/pvp/UHZwR2FtZTo2NDU0NTcxNQ?password=5b415433d151b3ca</p>
            <p>https://www.csgoroll.com/en/pvp/UHZwR2FtZTo2NDU0NTcxNQ?password=2a1db037ce2ee6a5</p>
        </div>
        </>
    );
}