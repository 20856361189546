import { useEffect, useState } from 'react';
import './index.scss';

type LeaderboardProps = {
  title: string;
  dataSource: string;
  timestamp: string;
  ended?: boolean;
}
export const LeaderboardResults = (props: LeaderboardProps) => {
  const [data, setData] = useState<Record<string,any>[]>([]);

  useEffect(() => {
    import(`./data/${props.dataSource}.json`).then((module) => {
      setData(module.default);
    })
  }, [props.dataSource]);


  const whitelist = (value: string) => {
    const maps : Record<string, string> = {
      "D O*P E": "D * * E",
      "\ud835****\udc52": "a*e",
    };
    if (maps[value]) return maps[value];
    return value;
  };

  const filler = {
    name: "Empty",
    hiddenName: "Empty",
    amount: "0.00",
  };

  if(data.length === 0) {
    return <p>Loading...</p>
  }

  const results = data;

  if (results.length < 15) {
    for (let i = results.length + 1; i <= 15; i++) {
      results.push({
        ...filler,
        position: i,
      });
    }
  }

  const names = results.map((result) => result.hiddenName);
  const amounts = results.map((result) => result.amount);

  
  return (
    <div className="results">
      <p>{props.ended? "Ended" : "Updated" } at {props.timestamp}</p>
      <p><i>It may take up to 24 hours for data to be displayed properly.</i></p>
        <div className="container">
            <div className="positions">
                <span> Position </span>
                {results.map((result) => (<span key={result.position}>{result.position}</span>))}
            </div>
            <div className="names">
                <span> Name </span>
                {names.map((name) => (<span key={name}>{whitelist(name)}</span>))}
            </div>
            <div className="deposited">
                <span> Deposited </span>
                {amounts.map((amount, i) => (<span key={i}>{amount.toFixed(2)}</span>))}
            </div>
            <div className="reward">
                <span> Tickets </span>
                {results.map((result) => {
                    const reward = result.position === 1 ? "🎫👑" :  "🎫";
                    const tickets = result.amount.toFixed(2).split('.')[0];
                    return <span key={result.position}>{tickets}{reward}</span>
                })}
            </div>
        </div>
    </div>
  );
}